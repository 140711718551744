import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Ils sont ingrats" />
    <h3 className='underline-title'>Ils sont ingrats</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Frotte leurs manches<br />
        Risque ta vie pour eux <br />
        Rends-leur divers services <br />
        Donne-leur ta femme ou ta fille <br />
        Si elle est belle bien entendu <br />
        Rampe pour les adorer<br />
        Ils t'écraseront les colonnes vertébrales <br />
        Ils te feront sauter le nerf viril <br />
      </p>
      <p>
        Leurs bouches ignorent le moindre grain de merci <br />
        Les puissants du plan inférieur <br />
      </p>
      <p>
        Ils sont comme ça <br />
        C'est leur nature <br />
        Tu ne les changeras jamais <br />
        Jérémie Jésus Ghandi ne les ont pas encore changés <br />
      </p>
      <p>
        Au demeurant <br />
        T'ont-ils demandé de leur rendre service?<br />
        Pourquoi alors te plainds-tu d'eux?<br />
        Ils n'ont pas du tout besoin de ton service<br />
        Ils ont leurs propres valets à leurs services<br />
      </p>
      <p>
        Et alors?...<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
